var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:({
    borderRadius: (_vm.radius + "px"),
    backgroundColor: 'white',
    boxShadow: _vm.customShadow
      ? _vm.customShadow
      : "0 13px 25px 0 rgba(0,0,0,.05) !important",
    border: '1px solid rgba(0,0,0,.05)'
  }),attrs:{"ripple":_vm.ripple}},[_c('v-card-text',{class:[_vm.padding ? '' : 'px-0 py-0']},[_vm._t("default",null,{"hide":_vm.hide})],2),(!_vm.noActions)?_c('v-card-actions',[_vm._t("actions")],2):_vm._e(),(_vm.collapsable)?_c('v-layout',{attrs:{"column":"","align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"absolute-pos":""}},[_c('DefaultButton',_vm._g({staticClass:"button-display default-shadow pr-6",class:[_vm.hide ? 'button-display-min' : ''],staticStyle:{"opacity":"1 !important"},attrs:{"small":"","bgColor":'white',"color":"black"},on:{"click":function($event){$event.preventDefault();return _vm.toggleHide.apply(null, arguments)}}},_vm.on),[_c('v-icon',{staticClass:"mr-2 ml-4",staticStyle:{"vertical-align":"middle"},attrs:{"small":"","outline":""}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.hide ? "Rozwiń" : "Zwiń")+" ")],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }