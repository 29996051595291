<template>
  <v-card
    :ripple="ripple"
    :style="{
      borderRadius: `${radius}px`,
      backgroundColor: 'white',
      boxShadow: customShadow
        ? customShadow
        : `0 13px 25px 0 rgba(0,0,0,.05) !important`,
      border: '1px solid rgba(0,0,0,.05)'
    }"
  >
    <v-card-text :class="[padding ? '' : 'px-0 py-0']">
      <slot v-bind:hide="hide"></slot>
    </v-card-text>
    <v-card-actions v-if="!noActions">
      <slot name="actions"></slot>
    </v-card-actions>
    <v-layout v-if="collapsable" column align-center justify-center>
      <v-flex absolute-pos>
        <DefaultButton
          v-on="on"
          class="button-display default-shadow pr-6"
          small
          style="opacity: 1 !important"
          @click.prevent="toggleHide"
          :bgColor="'white'"
          color="black"
          :class="[hide ? 'button-display-min' : '']"
        >
          <v-icon
            small
            style="vertical-align: middle"
            class="mr-2 ml-4"
            outline
            >{{ icon }}</v-icon
          >
          {{ hide ? "Rozwiń" : "Zwiń" }}
        </DefaultButton>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: {
    shadow: {
      type: Number,
      default: 0.1,
    },
    radius: {
      type: Number,
      default: 8,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    customShadow: {},
  },
  data() {
    return {
      hide: true,
    };
  },
  computed: {
    icon() {
      if (this.hide) {
        return "mdi-chevron-down";
      } else {
        return "mdi-chevron-up";
      }
    },
  },
  methods: {
    toggleHide() {
      this.hide = !this.hide;
      this.$emit("hide", this.hide);
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
};
</script>

<style scoped lang="css">
.collapse-card {
  box-shadow: none;
  text-decoration: none;
}
.button-display {
  top: 0px;
}
.button-display-min {
  top: 0;
}
.btn-shadow {
  border-radius: 15px !important;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
.absolute-pos {
  position: absolute;
}
</style>